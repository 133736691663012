import React from 'react'
import { graphql } from 'gatsby'
import { Layout, HTMLRenderer, PageWrapper } from '@retina-packages/retina-theme-bootstrap'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { hcpDIRD } from './constants';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { DiagnosingInheritedRetinal, HcpInheritedRetinalDisease, HcpMobileBanner } from './styles';
import { deriveMultiLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder, infographicDataBuilder } from '../../../utils';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders'
import retinaConfig from '../../../utils/retinaConfigs';
import CircularInfographics from '@retina-packages/retina-theme-bootstrap/packages/components/InfographicDonut/CircularInfographics'

const DiagnosingIRDTemplate = (props: Props) => {
  /**
   * Global section data building
   */
  const pgProp: PageQueryResult = props.data
  const blocksDIRD = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsDIRD = props.pageContext
  const htmlStaticFilesDIRD = {
    nonSVGImages: pgContextsDIRD.nonSVGImages,
    svgMediaImages: pgContextsDIRD.svgMediaImages,
    allMediaDocument: pgContextsDIRD.documents
  }
  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpDIRD.hcpDIRDMainMenu, siteFooterMenu: hcpDIRD.hcpDIRDFooterMenu, addHCPPrefix: retinaConfig.addHcpPrefix });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = hcpDIRD.hcpFooter
  const exitPopupTitle = hcpDIRD.hcpExitPopup
  const hcpSwitcherTitle = hcpDIRD.hcpHCPSwitcher
  const topNavTitle = hcpDIRD.hcpTopNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string
  } = reusableDataBuilders({blocks: blocksDIRD, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});

  const siteLogos = deriveMultiLogo({blocks: blocksDIRD, title: hcpDIRD.hcpSiteLogo})
  const bannerHTML = fullHTMLDataBuilder({blocks: blocksDIRD, title: hcpDIRD.hcpDIRDBanner})
  const hcpDIRDReference = footRefHTMLBuilder({ blocks: blocksDIRD, title: hcpDIRD.hcpDIRDFootRef })

  /**
   * Page specific data building
   */
  const overlapHTML = fullHTMLDataBuilder({blocks: blocksDIRD, title: hcpDIRD.overLapDIRD})
  const goingBeyondHTML = fullHTMLDataBuilder({blocks: blocksDIRD, title: hcpDIRD.goingBeyondDIRD})
  const familityHistoryHTML = fullHTMLDataBuilder({blocks: blocksDIRD, title: hcpDIRD.familyHistoryDIRD})
  const { per, text1, text2 } = infographicDataBuilder({ blocks: blocksDIRD, title: hcpDIRD.infographicDIRD })
  const caseButtonHTML = fullHTMLDataBuilder({blocks: blocksDIRD, title: hcpDIRD.caseButtonDIRD})
  const earlyTestHTML = fullHTMLDataBuilder({blocks: blocksDIRD, title: hcpDIRD.earlyTestDIRD})
  const donutInfoGraData = {
    per: `${per}`,
    circular_text: text1,
    right_text: text2
  }
  return (
    <HcpInheritedRetinalDisease>
      <HcpMobileBanner className="hcpmobilewrapper">
        {props.pageContext !== null && props.pageContext.metaInfo !== null && (
          <MetaTagContainer metaData={props.pageContext.metaInfo} />
        )}
        <Layout
          title={"siteTitle"}
          modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
          location={props.location} data={mainMenu}
          mdata={footerMenu}
          footerData={footerText}
          audience={audience}
          footref={hcpDIRDReference}
          exitPopData={exitData}
          hcplinks={hcpSwitcher}
          topNavigation={topNavs}
          siteLogos={siteLogos}
          gtmBackToTopLabel={hcpDIRD.backToTopGTM}
          backToTopContent={retinaConfig.backToTopContent}
          staticMediaFiles={htmlStaticFilesDIRD}
          showRefContent={retinaConfig.showRefContent}
          hideRefContent={retinaConfig.hideRefContent}
          hcpValidate={retinaConfig.hcpValidate}
          preIndexUrl = {retinaConfig.preIndexUrl}
          hcpHomeUrl={retinaConfig.hcpHomeUrl}
          hcpPrefix={retinaConfig.hcpPrefix}
          siteHomeUrl={retinaConfig.siteHomeUrl}
          footerClassName={hcpDIRD.footerClassName}
          languageConfig={{... retinaConfig.langConfig, ...hcpDIRD.redirectLangUrl}}
          {... {...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
        >
          <PageWrapper className='pagewrapper diagnosing-inherited-retinal-diseases'>
          <DiagnosingInheritedRetinal className="hcpinheritedretinal">
            {/* Banner section */}
            <MobileBanner className="mobile-banner clinical-trials-banner">
              <HTMLRenderer data={htmlStaticFilesDIRD} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true}/>
            </MobileBanner>
            <div className='main-section o-top-space'>
              <HTMLRenderer data={htmlStaticFilesDIRD} html={overlapHTML} />
              <HTMLRenderer data={htmlStaticFilesDIRD} html={goingBeyondHTML} />
              <div className="c-family--story inherited-retinal-disease-c-family mt-0 mb-0">
                <div className="o-container">
                  <div className="c-family--story__inner o-border-gradient o-bottom-space infogrphic-color">
                    <HTMLRenderer data={htmlStaticFilesDIRD} html={familityHistoryHTML} />
                    <CircularInfographics
                      data={donutInfoGraData}
                      audience={audience}
                    />
                    <HTMLRenderer data={htmlStaticFilesDIRD} html={caseButtonHTML} tagName='div' className='case-study-btn' />
                  </div>
                </div>
              </div>
              <HTMLRenderer data={htmlStaticFilesDIRD} html={earlyTestHTML} />
            </div>
          </DiagnosingInheritedRetinal>
          </PageWrapper>
        </Layout>
      </HcpMobileBanner>
    </HcpInheritedRetinalDisease>
  )
}

export default DiagnosingIRDTemplate

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... DiagnosingIRDPageQuery
      }
    }
  }
`
