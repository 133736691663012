export const hcpDIRD: any = {
  hcpDIRDMainMenu: "Main Navigational Menu HCP GCSO",
  hcpDIRDFooterMenu: "Footer Navigation Menu HCP GCSO",
  hcpFooter: "Retina Global Footer HCP - Canada",
  hcpExitPopup: "Retina Canada HCP Exit Popup HTML",
  hcpHCPSwitcher: "HCP Switcher Modal Pop Up",
  hcpTopNav: "Top Navigational Menu HCP - Canada",
  hcpSiteLogo: "Site logos HCP",
  hcpDIRDBanner: "Banner - HTML - DIRD - HCP - Canada",
  hcpAmericalData: "American Academy of Ophthalmology HTML - Canada",
  hcpAdvancingScience: "Advancing Science HTML - Canada",
  hcpOverlap: "Overlapping presentations of IRD HTML - Canada",
  hcpDIRDFootRef: "Diagnosing Inherited Retinal Diseases HCP - References",
  hcpDIRDVideoCarousel: "DNA Video and Carousel",
  overLapDIRD: "Diagnosing Inherited Retinal Diseases - overlapping presentations New",
  goingBeyondDIRD: "Diagnosing Inherited Retinal Diseases - Going beyond the clinical",
  familyHistoryDIRD: 'Diagnosing Inherited Retinal Diseases - Family history',
  infographicDIRD: "Diagnosing Inherited Retinal Diseases - Infographic",
  caseButtonDIRD: "Diagnosing Inherited Retinal Diseases - Read case study button",
  earlyTestDIRD: "Diagnosing Inherited Retinal Diseases - Early testing matters",
  redirectLangUrl: {
    "redirectUrl": {
      "en": "/hcp/inherited-retinal-diseases",
      "fr": "/fr/hcp/inherited-retinal-diseases"
    }
  },
  backToTopGTM: "Back to top - Inherited Retinal Diseases",
  footerClassName: "inherited-retinal-diseases-footer",
}

const size = {
  mobilesmax: '320px'
}

export const deviceLocal = {
  mobilesMax: `(max-width: ${size.mobilesmax})`
}
